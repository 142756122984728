import Service from "../Service";

const resource = "assingbingtoline/";

export default {

    save(abt, requestID){
        return Service.post(resource + "save", abt,{
            params: { requestID: requestID }
        });
    },

    pagination(parameters, requestID) {        
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    getlinesprocess(abt, requestID){
        return Service.post(resource + "getlinesprocess", abt,{
            params: {requestID: requestID},
        });
    },
    
    
}