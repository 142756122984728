<template>
    <div class="col-md-12 col-sm-12">
        <v-card class="pa-md-6 mx-lg-auto">
            <v-row justify="space-around" no-gutters>
                <v-col cols="4">
                    <s-select
                        :items="itemsWorkStation"
                        item-value="PwsID"
                        item-text="PwsDescription"
                        label="Seleccione Estacion de Trabajo"
                        v-model="processInitial.PwsID">

                    </s-select>
                </v-col>
                <v-col cols="3">
                    <s-select
                        :items="itemsGroup"
                        item-value="PggID"
                        item-text="PggNameGroup"
                        label="Seleccione Grupo"
                        v-model="processInitial.PggID">
                        
                    </s-select>
                </v-col>
                <v-col cols="3">
                    <s-select                    
                        label="Turno de Produccion"
                        :items="itemTurn"
                        item-value="TrnID"
                        item-text="TnrObservation"
                        v-model="processInitial.TrnID"
                            >
                    </s-select>
                </v-col>
                <v-col cols="auto ">
                    <v-btn 
                        small
                        fab
                        elevation="3"
                        color="info" @click="save()">
                        <v-icon style="font-size: 16px !important;"
							>fa-thin fa-save</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        
        </v-card>
    </div>
</template>
<script>

    import _sWorkStation from '@/services/FrozenProduction/FrzPackingWorkStation.js';
    import _PackingGroup from "@/services/FrozenProduction/FrzPackingGroupService.js"; 
    import _sTurnService from "@/services/FreshProduction/PrfTurnService";

    import sServiceProcess from "@/services/FrozenProduction/FrzPackingStationGroupShift.js";

    export default {
        data(){
            return {
                itemsWorkStation:[],
                itemsGroup:[],
                itemTurn:[],
                processInitial: {}
            }
        },
        created(){
            this.getWorkStation();
            this.getGroup();
            this.TurnActive();
        },
        methods:{
            getWorkStation()
            {
                _sWorkStation.list({}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {
                        this.itemsWorkStation = r.data;
                    }
                });
            },
            getGroup()
            {
                _PackingGroup.list({TypeZoneWork: 2}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {
                        this.itemsGroup = r.data;
                    }
                });
            },
            TurnActive(){
                let TypeArea = 5
            _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemTurn = r.data;                                   
                    }
                });      
            },
            save(){
                if(this.processInitial.PwsID == undefined)
                {
                    this.$fun.alert("Selecciones estacion de trabajo", "warning");
                    return;
                }
                if(this.processInitial.PggID == undefined)
                {
                    this.$fun.alert("Selecciones grupo", "warning");
                    return;
                }
                if(this.processInitial.TrnID == undefined)
                {
                    this.$fun.alert("Seleccione Turno", "warning");
                    return;
                }
                this.processInitial.UsrCreate = this.$fun.getUserID();
                this.$fun.alert("Desea registrar proceso", "question").then(r => {
                    if(r.value)
                    {
                        sServiceProcess.save(this.processInitial, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Se registro correctamente", "success");
                                this.$emit("refreh");
                            }
                        });
                    }
                })
                
            }
        }
    }
</script>