<template>
    <div>
        <s-toolbar dense dark color="primary" @close="close()" @save="save()" save close label="Malla"></s-toolbar>
        <v-card class="pa-md-4 mx-lg-auto">
            <v-row>
                <v-col lg="12" md="12" cols="12">
                    <v-card-title class="primary--text">{{ itemTable. PwsDescription}}</v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs class="ma-0 pa-0">
                            <v-row>
                                <v-col>
                                    <s-text
                                        readonly
                                        label="Tipo de Empaque"                                      
                                        v-model="TypePackingName"
                                        return-object
                                       >

                                    </s-text>
                                </v-col>
                                <v-col>
                                    <s-text
                                        readonly
                                        label="Peso promedio de bolsa"
                                        decimal
                                        v-model="WeightAverageBag">

                                    </s-text>
                                </v-col>
                                <v-col>
                                    <s-text
                                        label="Cantidad de bolsas"
                                        decimal
                                        v-model="NumberOfBags">

                                    </s-text>
                                </v-col>     
                            </v-row>
                            <v-row>
                                <v-col>
                                    <s-text
                                        label="Peso Neto"
                                        decimal
                                        v-model="WeightNet">

                                    </s-text>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <s-text
                                        readonly
                                        label="Cliente"
                                        v-model="CumNombre"
                                        full>
                                    </s-text> 
                                </v-col>
                                <v-col >
                                    <s-select 
                                        :items="itemsLineProcess"
                                        item-value="PlpId"
                                        item-text="PloDescription"
                                        label="Seleccionar Linea en proceso"                         
                                        return-object
                                        v-model="item.objLineOut"                        
                                        :slotTmp="true"
                                        >
                                        
                                    </s-select>
                                </v-col>
                            </v-row>                            
                        </v-container>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
    import SToolbar from '../../../../components/Utils/SToolbar.vue'
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    import _sAssingBingToLine from "@/services/FrozenProduction/FrzAssingBingToLine.js"
    
    export default{
        components: { SToolbar, SSelectClient },
        props: {
            itemTable:{                
                default: null,
                type: Object,
            }
        },        
        data(){
            return{
                CumNombre: "",
                itemsLineProcess: [],
                objLineOut: {},
                item:{},
                WeightNet: 0,
                NumberOfBags: 0,
                WeightAverageBag: 0,
                // TypeBagPacking: {},
                TypePackingName: "",
                Period: {},
            }
        },methods:{
            close(){
                this.$emit("closeCreateMesh");
            },
            initialize()
            {
                _sAssingBingToLine.getlinesprocess(this.itemTable, this.$fun.getUserID())
                .then(r =>{
                    if(r.status == 200)
                    {
                        this.itemsLineProcess = r.data;                        
                    }
                });
            }, 
            save(){
                this.$fun.alert("Estas seguro de guardar?", "question").then((r) => {
                    
                    this.item.PwpID = this.itemTable.PwpID;
                    this.item.AbbID = this.itemTable.AbbID;
                    this.item.TrnID = this.itemTable.TrnID;
                    this.item.NumberOfBags = this.NumberOfBags; 
                    this.item.typeArmed = 2;//retail
                    this.item.TypePacking = this.itemTable.TypePacking;
                    this.item.WeightNet = this.WeightNet;
                    this.item.TraceabilityID = this.itemTable.TraceabilityID;
                    this.item.PliID = this.itemTable.PliId;
                    this.item.CumID = this.itemTable.CumID;
                    this.item.PlpID = this.item.objLineOut.PlpID;
                    this.item.UsrCreateID = this.$fun.getUserID();
                    this.item.TypeCultive = this.itemTable.TypeCultive;
                    this.item.TypeCrop = this.itemTable.TypeCrop;
                    this.item.VrtID = this.itemTable.VrtID;
                    
                    if(r.value){
                        _sAssingBingToLine.save(this.item, this.$fun.getUserID())
                        .then(r =>{
                            if(r.status == 200){
                                this.$fun.alert("Se registro correctamente", "success");
                                this.$emit("closeCreateMesh");
                            }
                        });
                    }                   
                });
            },
            // getWeightBag(itemBag){
            //     if(itemBag != undefined)
            //     {
            //         this.WeightAverageBag = itemBag.DedHelper;
            //     }
            // },
            ClickCalcular()
            {          
                if(this.WeightAverageBag != undefined && this.NumberOfBags != undefined){
                    this.SumTotalNet = this.WeightAverageBag * this.NumberOfBags;
                    this.WeightNet = this.SumTotalNet;     
                }                                
            }
        },
        watch:{
            NumberOfBags(){                
                this.ClickCalcular();
            },
            // WeightAverageBag(){
            //     this.ClickCalcular();
            // }
        },
               
        created()
        {          
            this.Period = this.itemTable.Period;   
            this.TypePackingName = this.Period.TypePackingName;
            this.WeightAverageBag = this.Period.WeightPacking;
            this.CumNombre = this.Period.CumNombre;
            this.initialize();
        },
        mounted(){
             
            // this.TypePacking = this.itemTable.Period;
            
        }
    }
</script>