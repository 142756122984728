
import Service from "../Service";

const resource = "assistanceworkstation/";

export default {
    saveroll(prd, requestID){
        return Service.post(resource + "saveroll", prd,{
            params: {requestID: requestID},
        });
    },

    saveassistance(prd, requestID){
        return Service.post(resource + "saveassistance", prd,{
            params: {requestID: requestID},
        });
    },

    list(prd, requestID){
        return Service.post(resource + "list", prd,{
            params: {requestID: requestID},
        });
    },
}