<template>
    <div style="padding-top:-2px;margin-top:-2px;">
        <template v-if="checkList == false">
            <v-label>
                <b>{{ label }}</b>
            </v-label>
            <!-- <s-select-generic
                autocomplete 
                class="text"
                itemvalue="CumId" 
                :disabled="disabled"
                :value="value"
                id="CumId" itemtext="CumNombre"
                :return-object="returnObject"
                @input="input($event)"
                :config="config"
                :full="full"
                :text="text"
                :clearable="clearable"
                no-default>
            </s-select-generic> -->
            <s-select-generic 
                style="margin-top:0px;"
                :config="config"
                :disabled="disabled"
                
               
                itemvalue="CumId" 
                :clearable="clearable"
                :itemtext="abbr ? 'CumNombre' : 'CumNombre'"
                
                :id="id"
                :noDefault="clearable"
                :value="valueLocal"
                @input="input($event)"
                
                :returnObject="returnObject"
               
               >
                
            </s-select-generic>
        </template>

        <!-- <div v-if="check">
            <v-label>
                <b>{{ label }}</b>
            </v-label>
            <v-checkbox
                hide-details=""
                v-for="(item, i) in items"
                :value="item[id]"
                :disabled="disabled"
                :readonly="readonly"
                v-model="selected"
                :label="abbr ? item.CumNombre : item.CumNombre"
                :key="i"
            />
        </div>  -->

        <div v-if="checkList">
      
        <v-select multiple :items="items" v-model="selected" :returnObject="returnObject" :clearable="clearable" item-value="CumId" :label="label" item-text="CumNombre">
        
        </v-select>
    </div>
    </div>
</template>

<script>
    import _sCustomerMaduration from "@/services/FrozenProduction/FrzCustomersMaturationService";

    export default {
        components: {},
        props: 
        {
            id: {
            type: String,
            default: "CumID",
            },
            disabled: {
            type: Boolean,
            default: false,
            },
            clearable: {
            type: Boolean,
            default: false,
            },
            value: null,
            text: { type: String, default: "" },
            label: {
            type: String,
            default: "",
            },
            abbr: {
            type: Boolean,
            default: false,
            },
            returnObject: {
            type: Boolean,
            default: false,
            },
            produceID:{
            type: Number,
            default: 0,
            },
            position:{
            type: Number,
            default: 0,
            },
            noDefault: {
            type: Boolean,
            default: false,
            },    
            full: {
            type: Boolean,
            default: false,
            },
            checkList: {
            type: Boolean,
            default: false,
            },
        },
        watch: {
            value(value) {
                this.item = value;
                
                this.valueLocal=this.value;
            },
            produceID(){
                this.config.params.SpvId=this.produceID
            },
            selected(value) {
                
            let f = this.items.find((x) => x[this.id] == value);
            if (!this.returnObject) this.$emit("input", value);
            else this.$emit("input", f);
            },
        },
        methods:{
            input(val){
                this.$emit("input", val);
            }
        },
        data: () => ({
            item: 0,
            val: "",
            items: [],
            config: {},
            selected: [],
            valueLocal:null,
        }),
        created(){

            this.config = {
                url: _sCustomerMaduration.getUrlListClientMaturation(),
                title: this.label,
                params: { search: "", requestID: this.$fun.getUserID(), SpvId: this.position}
            };

            if(this.checkList)
            {          
           
                this.$http
                .post(
                    _sCustomerMaduration.getUrlListClientMaturation(),
                    {},
                    {
                    params: this.config.params,
                    }
                )
                .then((r) => {
                    if (r.status == 200) {
                    this.items = r.data;
                    this.$emit("getDataInitial", this.items);
                    }
                });
            }
        },
    };
</script>