import Service from "../Service";
import Vue from "vue";
const resource = "Turn/"

export default {
    save(prflin, requestID) {
        return Service.post(resource + "save", prflin, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    turnexist(RequestID, area) {
        return Service.post(resource + "turnexist", {}, {
            params: { RequestID: RequestID, typearea: area }
        });
    },
    receptionFresh(RequestID, obj) {
        return Service.post(resource + "receptionFresh", obj, {
            params: { RequestID: RequestID }
        });
    },


    turnActive(obj, requestID) {
        return Service.post(resource + "turnActive", obj, {
            params: { RequestID: requestID }
        });
    },
    
};