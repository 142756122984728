<template>
    <div class="col-md-12 col-sm-12">
        <v-row  >
            <v-col >                 
                <v-row no-gutters>
                    <v-col cols="auto">
                        <v-btn v-model="mostrar" 
                            fab 
                            x-small 
                            elevation="3"
                            @click="mostrar = !mostrar">
                            <i class="fas fa-plus"></i>
                        </v-btn>
                
                    </v-col>
                    <v-col  cols="11">
                        <packing-station v-if="mostrar" @refreh="refresh()">

                        </packing-station>
                    </v-col>
                </v-row>                
            </v-col>
        </v-row>
        <v-row>
            <v-divider></v-divider>
        </v-row>
        <v-row  justify="center">
            <v-col v-for="(table, i) in tableProcess"
                :key="i" cols="auto" >
                <v-card  dense :color="color" width="180">
                    <v-card-title class="black--text text-h5 center">
                        {{ table.PwsDescription }}
                    </v-card-title>
                   <v-card-text>
                    
                    <div >
                        <v-badge  color="warning" >
                            <template v-slot:badge>
                                <span>{{table.numberPerson}}</span>
                            
                            </template>
                            <v-btn 
                                color="info"
                                elevation="2"
                                fab
                                small                                        
                                depressed 
                                @click="GroupListPerson(table.PggID)"
                            >
                                <v-icon color="white" class="mdi-24px" >mdi-account-check</v-icon>
                            </v-btn>
                        </v-badge>
                    </div>
                   </v-card-text>
                    <v-card-actions>
                        <v-btn 
                            :disabled="table.AbbID == undefined"
                            fab
                            x-small
                            text color="surface-variant"
                            @click="btnCreateMesh(table)">
                            <v-icon color="white" class="mdi-24px" >mdi-cube</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="ShowGroupListPerson" persistent
            max-width="900"
            heighht="400"
            v-if="ShowGroupListPerson">
            <v-card>
                <s-toolbar label="Asistencia y rol del personal"
                    dark 
                    close 
                    @close="ShowGroupListPerson = false"
                    color="primary">
                </s-toolbar>
                <v-card-title>
                    <v-row>
                        <v-col>
                            <!-- Grupo :<h3>{{ this.PggID}}</h3> -->
                        </v-col>  
                        <v-spacer></v-spacer>                      
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    fab
                                    x-small
                                    color="primary"
                                    
                                    v-on="on"
                                    @click="SaveAssistance()"
                                >
                                    <v-icon>mdi-arrow-right-bold-box-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Asistencia y Rol</span>
                        </v-tooltip>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        v-model="selected"
                        dense
                        :headers="headerPackingGroup"
                        :items="ListGroupPerson"
                        :items-per-page="-1"                        
                        disable-sort                        
                        hide-default-footer
                        item-key="PgdID"
                        show-select
                        class="elevation-1">

                        <template v-slot:item.LpmHourInitial="{ item }"> 
                                    <v-icon small color="warning">fa-clock</v-icon> 
                                    {{item.LpmHourInitial}}
                        </template>

                        <template v-slot:item.TypeRoll="{ item }"> 
                            <s-select-definition
                                :def="1430"
                                v-model="item.TypeRoll"
                                clearable
                            ></s-select-definition>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="modalCreateMesh" v-if="modalCreateMesh" width="700" persistent>
            <armed-mesh :itemTable="itemTable" @closeCreateMesh="closeCreateMest()"></armed-mesh>
        </v-dialog>
    </div>
   
</template>        

<script>
    import PackingStation from "./FrzPackingStationGroupShift.vue"
    import _sServiceTableProcess from "@/services/FrozenProduction/FrzPackingStationGroupShift.js"

    import _sServiceAssistance from "@/services/FrozenProduction/FrzPackingAssistanceWorkStation.js"
   
    import _sTurnService from "@/services/FreshProduction/PrfTurnService";
    import _PackingGroup from "@/services/FrozenProduction/FrzPackingGroupService.js"; 

    import ArmedMesh from "./FrzPackingWorkStation/FrzPackingArmedMesh.vue"
    export default{
        components: {PackingStation, ArmedMesh},
        data(){
            return {
                item:{},
                mostrar: false,
                tableProcess: [],
                ShowGroupListPerson: false,
                color: '#5ebcb9',      
                ListGroupPerson:[],
                headerPackingGroup: [
                    { text:"item", value :"Line"},
                    { text: "Nombre", value: "PgdName"},
                    { text: "Apellidos", value: "PgdLastName"},
                    { text: "DNI", value: "PrsDocumentNumber"},
                    { text: "Hora de ingreso ", value: "LpmHourInitial"},
                    { text: "Rol", value: "TypeRoll", width: "200" },
                    // { text: "Guardar Rol", value: "saveRoll", align: "center"},


                ],     
                selected:[],
                PggID: 0,
                itemTurn: [],
                modalCreateMesh: false,
                itemTable: {}
            }
        },
       
        created(){
            this.TurnActive();
            
        },
        methods:{
            refresh(){
                this.TurnActive();
            },
            listtableProcess(TrnID)
            {
                _sServiceTableProcess.list({TrnID : TrnID}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {         
                        this.tableProcess = r.data;              
                        console.log("this.tableProcess->", this.tableProcess);               
                    }
                })
            },
            GroupListPerson(item)
            {
                this.selected = [];
                this.ShowGroupListPerson = true;
                this.PggID = item;
                this.getListGroupPerson(item);
            },
            getListGroupPerson(item)
            {
                console.log('item',item)
                let group = {}
                group.PggID = item;
                _PackingGroup.listdetail(group, this.$fun.getUserID()).then( r => {
                
                    if(r.status == 200){
                        r.data.forEach((element, index) => {
                            element.Line = index + 1;
                        })
                        this.ListGroupPerson = r.data;
                    }
                })
            },
            SaveRoll(item){
                console.log(item);                
            },
            SaveAssistance()
            {
                
                if(this.selected.length <= 0)
                {
                    this.$fun.alert("Selecciones personal", "warning");
                    return;
                }

                this.selected.forEach(element => {
                    element.UsrID = this.$fun.getUserID();
                    element.UsrUpdate = this.$fun.getUserID();
                    element.TrnID = this.itemTurn[0].TrnID;
                });
                this.$fun.alert("¿ Desea guardar asistencia?", "question")
                .then((r) => {
                    if(r.value)
                    {
                        _sServiceAssistance.saveassistance(this.selected, this.$fun.getUserID()).then((r) => {
                            this.$fun.alert("Se registro correctamente", "success");
                            this.getListGroupPerson(this.PggID);
                            this.TurnActive();
                        });
                    }
                });
            },
            TurnActive(){
                let TypeArea = 5
            _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemTurn = r.data;   
                        console.log(this.itemTurn[0].TrnID)
                        this.listtableProcess(this.itemTurn[0].TrnID);                                
                    }
                });      
            },
            btnCreateMesh(table)
            {
                this.itemTable = table;
                this.modalCreateMesh = true;
                this.TurnActive();
            },
            closeCreateMest(){
                this.modalCreateMesh = false;
            }
        }

    }
</script>
