import Service from "../Service";

const resource = "packinggroup/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    listdetail(prd, requestID) {
        return Service.post(resource + "listdetail", prd, {
            params: { requestID: requestID },
        });
    },   
    
    list(prd, requestID) {
        return Service.post(resource + "list", prd, {
            params: { requestID: requestID },
        });
    },  
    listdetaillinesin(prd, requestID) {
        return Service.post(resource + "listdetaillinesin", prd, {
            params: { requestID: requestID },
        });
    }, 

    listdetaillinesout(prd, requestID) {
        return Service.post(resource + "listdetaillinesout", prd, {
            params: { requestID: requestID },
        });
    }, 
   
}; 