import Service from "../Service";

const resource = "workstationprocess/";

export default {
    save(prd, requestID){
        return Service.post(resource + "save", prd,{
            params: {requestID: requestID},
        });
    },

    list(prd, requestID){
        return Service.post(resource + "list", prd,{
            params: {requestID: requestID},
        });
    },
}